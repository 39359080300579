import Logger from '@utils/log'
import api from '$lib/api'
import { createQueryString } from '@/utils'

const log = new Logger('actions/states')

export const fetchTrackingReport = async (initialDate, finalDate) => {
    const dateParams = {
        initialDate, 
        finalDate,
    }
    const queryParams = createQueryString(dateParams)
    
    try {
        
        const res = await api.get(`/admin/tracking-report${queryParams}`, {
            responseType: 'blob',
        })
        
        return res
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}