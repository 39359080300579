import { 
    PURCHASE_TYPE_COSTS,
    PURCHASE_TYPE_MIN_COSTS,
    PURCHASE_TYPE_AUTO_CHAIN,
} from '@constants'

/**
 * Calculates the cost of chaining.
 *
 * @param {number} numDocuments - The number of documents involved in the purchase.
 * @returns {number} - The calculated cost of the purchase.
 */
export const calculateChainingCost = (numDocuments, addMinCost = true) => {
    const totalCost = numDocuments * PURCHASE_TYPE_COSTS[PURCHASE_TYPE_AUTO_CHAIN]
    const minCost = PURCHASE_TYPE_MIN_COSTS[PURCHASE_TYPE_AUTO_CHAIN]
    
    return addMinCost ? Math.max(minCost, Math.ceil(totalCost)) : totalCost
}

/**
 * Calculates general cost of purchases
 * @param {string} purchaseType - The type of the purchase
 * @param {number} itemCount - The number of elements involved in the purchase.
 * @param {number} [pastPurchases] - The number of elements that have been purchased already
 * @returns {number} - The calculated cost of the purchase.
 */
export const calculateGeneralCost = (purchaseType, itemCount, pastPurchases = 0, addMinCost = true) => {
    const unit = PURCHASE_TYPE_COSTS[purchaseType] || 0
    const minCost = PURCHASE_TYPE_MIN_COSTS[purchaseType] || 0
    const price = unit * (itemCount - pastPurchases)
    
    return addMinCost ? Math.max(minCost, price) : price
}
