import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import ExampleManagerModal from '../../../src/components/exampleManager/ExampleManagerModal'
import * as store from '$store'
import { ENABLED_STATUS, EXAMPLE_TYPES, ST_VAL_EXT_KEY } from '@/constants/exampleManager'
import { useSearchParams } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import { toast } from 'react-toastify'
import InstructionAndDefinitionModal from './InstructionAndDefinitionModal'
import CreatePresetModal from './CreatePresetModal'
import CreateTemplateModal from './CreateTemplateModal'
import { Link } from 'react-router-dom'
import ExampleManagerTabs from './ExampleManagerTabs'

const DashboardExampleManager = () => {
    
    const [searchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    
    const examples = useWireValue(store.examples)
    const [markedDocuments, setMarkedDocuments] = useState([])
    const [showCreateExample, setShowCreateExample] = useState(false)
    const [example, setExample] = useState(null)
    const [includeSubTabModal, setIncludeSubTabModal] = useState(false)
    const [showCreatePreset, setShowCreatePreset] = useState(false)
    const [isInsertingToLiveTable, setIsInsertingToLiveTable] = useState(false)
    const [showInstructionDefinitionModal, setShowInstructionDefinitionModal] = useState(false)
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    const arrayToRender = useMemo(() => 
        examples.filter(ex => system === ex.system && templateId === ex.valueExtractorTemplateId),
    [examples, system, templateId])
    
    const deleteItems = async () => {
        await actions.deleteExamples(markedDocuments)
        toast.success(`Successfully removed ${markedDocuments} examples.`)
    }
    
    const loadFromLiveTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.loadFromLiveTable(system, templateId)
        setIsInsertingToLiveTable(false)
        
    }
    
    useEffect(() => {
        
        actions.getExamples()
        actions.getTemplates()
        
    }, [])
    
    return (
        
        <div className="p-4 w-full">
            <header>
                <h3>Example Manager</h3>
            </header>
            <div>
                <ExampleManagerTabs setIncludeSubTabModal={setIncludeSubTabModal} />
                
                <div className="flex flex-col">
                    <div className="flex gap-4 mt-4 w-full justify-between">
                        <div>
                            <button
                                className="btn btn-link"
                                onClick={() => setShowInstructionDefinitionModal(true)}>
                                Edit Template
                            </button>
                        </div>
                        
                        <div className="flex flex-row gap-2">
                            <button
                                className="btn btn-primary"
                                onClick={() => setShowCreatePreset(true)}> Create Preset </button>
                            <Link
                                className="btn btn-secondary"
                                to={`presets?system=${system}&templateId=${templateId}`}>
                                    View Presets
                            </Link>
                            <div className="divider divider-horizontal m-0"></div>
                            <button 
                                className="btn btn-primary"
                                disabled={isInsertingToLiveTable}
                                onClick={() => loadFromLiveTable()}> Load from LiveTable </button>
                            <button
                                className={cn('btn btn-error', {
                                    'opacity-20': !markedDocuments.length,
                                })}
                                onClick={() => {
                                    deleteItems()
                                    setMarkedDocuments([])
                                }}
                                disabled={markedDocuments.length === 0}> Delete </button>
                        </div>
                    </div>
                    <table className="mt-4 w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Document Name</th>
                                <th>Type</th>
                                <th>Subtype</th>
                                <th>Description</th>
                                <th>Example Type</th>
                                <th>Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayToRender.map(example => {
                                return (
                                    <tr key={example.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={markedDocuments.includes(example.id)}
                                                onChange={event => {
                                                    if (event.target.checked)
                                                        setMarkedDocuments(prevToDelete =>
                                                            [...prevToDelete, example.id])
                                                    else
                                                        setMarkedDocuments(prevToDelete => prevToDelete
                                                            .filter(id => id !== example.id))
                                                }} />
                                        </td>
                                        <td>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setShowCreateExample(true)
                                                    setExample(example)
                                                }}>
                                                {example.document?.name}
                                            </div>
                                        </td>
                                        <td>{example.type}</td>
                                        <td>{example.subType}</td>
                                        <td>
                                            <div className="truncate">
                                                {example.description}
                                            </div>
                                        </td>
                                        <td>
                                            <select
                                                id="exampleType"
                                                className="select select-bordered"
                                                value={example.exampleType}
                                                onChange={async e => 
                                                    await actions.changeExampleType(example.id, e.target.value)
                                                }>
                                                <option></option>
                                                {EXAMPLE_TYPES.map(it => (
                                                    <option key={it.key} value={it.key}>
                                                        {it.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                id="enabled"
                                                className="select select-bordered"
                                                value={example.enabled}
                                                onChange={async e => 
                                                    await actions.changeEnabledValue(example.id, e.target.value)
                                                }>
                                                <option></option>
                                                {ENABLED_STATUS.map(it => (
                                                    <option key={it.key} value={it.key}>
                                                        {it.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                
                </div>
            </div>
            
            {showInstructionDefinitionModal && (
                <InstructionAndDefinitionModal
                    showInstructionDefinitionModal={showInstructionDefinitionModal}
                    setShowInstructionDefinitionModal={setShowInstructionDefinitionModal}
                    system={system}
                    templateId={templateId} />
            )}
            
            {showCreatePreset && (
                <CreatePresetModal
                    showCreatePreset={showCreatePreset}
                    setShowCreatePreset={setShowCreatePreset}
                    templateId={templateId}
                    system={system} />
            )}
            
            {includeSubTabModal && (
                <CreateTemplateModal
                    includeSubTabModal={includeSubTabModal}
                    setIncludeSubTabModal={setIncludeSubTabModal}
                    system={system}/>
            )}
            
            {showCreateExample && (
                <ExampleManagerModal
                    show={showCreateExample}
                    handleClose={() => setShowCreateExample(false)}
                    example={example}
                    document={example.document} />
            )}
        </div >
    )
}

export default DashboardExampleManager