import Select from 'react-select'
import './Selectbox.css'

export function labelToOption(label, index) {
    return {
        label: `${label}`,
        value: index ? `${index}` : label,
    }
}

/* export interface SelectboxOption<T = string, K = []> {
    label: string
    value: T
    list?: K
}

type SelectboxProps = {
    name: string
    option?: SelectboxOption
    options: SelectboxOption[]
    handleChange: (name: string, value: SelectboxOption | null) => void
    label?: string
    labelClassName?: string
    description?: string
    className?: string
    errorClassName?: string
    placeholder?: string
    menuIsOpen?: boolean
    maxMenuHeight?: number
} */

export const Selectbox = ({
    name,
    option,
    label,
    labelClassName,
    description,
    disabled,
    className = 'col-6',
    errorClassName = '',
    options,
    handleChange,
    menuIsOpen,
    placeholder,
    maxMenuHeight,
}) => {
    
    return (
        <div className={`form-group ${className} ${errorClassName}`}>
            {label && <label className={labelClassName}>{label}</label>}
            {description && (
                <div className="row">
                    <p className="text-muted col-11">{description}</p>
                </div>
            )}
            <Select
                menuIsOpen={menuIsOpen}
                options={options}
                value={option}
                isDisabled={disabled}
                className="selectbox"
                classNamePrefix="selectbox"
                maxMenuHeight={maxMenuHeight}
                name={name}
                onChange={value => {
                    handleChange(name, value)
                }}
                placeholder={placeholder} />
        </div>
    )
}
