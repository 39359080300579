import { useState, useEffect } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { toast } from 'react-toastify'

import Modal from '$components/Modal'
import { useWireValue } from '@forminator/react-wire'
import { SYSTEM_TYPES } from '@/constants/exampleManager'

const ManageLLMPromptModal = ({
    id = null,
    routeBase,
    ...props
}) => {
    
    const [open, setOpen] = useWireState(store.modalManageLLMPromptOpen)
    const subTabs = useWireValue(store.systemTemplates)
    const [promptConfig, setPromptConfig] = useState({})
    
    const updatePromptConfigByKey = (key, value) => setPromptConfig({ ...promptConfig, [key]: value })
    
    const concatKey = key => key + '_templateId'
    
    const savePromptConfig = async () => {
        const savedPrompt = await actions.savePromptConfig(promptConfig, routeBase)
        
        setPromptConfig(savedPrompt)
        toast.success('LLM Prompt Configuration updated.')
    }
    
    useEffect(() => {
        
        setPromptConfig({})
        
        if (id) {
            
            actions.getPromptConfig(id, routeBase).then(data => setPromptConfig(data))
            actions.getTemplates()
        }
        
    }, [id])
    
    if (!id) return null
    
    return (
        
        <Modal
            className="max-w-full"
            open={open}
            setOpen={setOpen}
            title="LLM Prompt Configuration"
            actionLabel="SAVE"
            actionOnClick={savePromptConfig}
            {...props}>
            <h5>Templates</h5>
            <div className="mb-4 flex flex-col gap-4 bg-base-200 p-4 rounded">
                {SYSTEM_TYPES.map(type => {
                    return <div className="flex" key={type.key}>
                        <label className="flex-1">{type.value}:</label>
                        <select
                            className="flex-1 select select-bordered select-xs"
                            value={promptConfig[concatKey(type.key)] || ''}
                            onChange={e => updatePromptConfigByKey(concatKey(type.key), e.target.value || null)}>
                            <option value=""></option>
                            {subTabs.filter(st => st.system === type.key).map(it => (
                                <option key={it.id} value={it.id}>{it.name}</option>
                            ))}
                        </select>
                    </div>
                })}
            </div>
            
            <h5>Target Table</h5>
            <div className="flex flex-col gap-4 bg-base-200 p-4 rounded">
                <div className="flex gap-4">
                    <input
                        id="live-table"
                        value="liveTable"
                        type="radio"
                        checked={promptConfig.targetTable === 'liveTable'}
                        className="radio"
                        onChange={e => updatePromptConfigByKey('targetTable', e.target.value)}>
                    </input>
                    <label htmlFor="live-table" className="cursor-pointer">
                        Live Table
                    </label>
                </div>
                <div className="flex gap-4">
                    <input
                        id="working-table"
                        value="workingTable"
                        type="radio"
                        checked={promptConfig.targetTable === 'workingTable'}
                        className="radio"
                        onChange={e => updatePromptConfigByKey('targetTable', e.target.value)}>
                    </input>
                    <label htmlFor="working-table" className="cursor-pointer">
                        Experimental Table
                    </label>
                </div>
            </div>
        
        </Modal>
        
    )
    
}

export default ManageLLMPromptModal
