import { useEffect, useState } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { toast } from 'react-toastify'
import { MdOutlineEdit } from 'react-icons/md'

import * as actions from '$actions'
import * as store from '$store'
import * as sysStore from '@store'
import { snippet } from '@/utils'

import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { SYSTEM_TYPE_CHAINER } from '@/constants/exampleManager'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { Link } from 'react-router-dom'

const PresetDocumentsModal = ({
    collections,
    selectedPreset,
    setSelectedPreset,
    setPresetDocument,
    showDocumentsModal,
    setShowDocumentsModal,
    setShowChangeClonedPresetName,
    setShowEditExampleModal,
    setShowConfirmationModal,
}) => {
    
    const runsheets = useWireValue(sysStore.runsheets)
    const presetDocuments = useWireValue(store.presetDocuments)
    const [collectionId, setCollectionId] = useState('')
    const [runsheetId, setRunsheetId] = useState('')
    const [isInserting, setIsInserting] = useState(false)
    const [evaluations, setEvaluations] = useState([])
    
    const handleClose = () => {
        setCollectionId('')
        setRunsheetId('')
        setSelectedPreset(null)
        setShowDocumentsModal(false)
    }
    
    const copyToWorkingTable = async () => {
        setIsInserting(true)
        await actions.copyToWorkingTable(selectedPreset.id)
        toast.success(`Preset "${selectedPreset.name}" copied to the Experimental Table.`)
        setIsInserting(false)
        handleClose()
        
    }
    
    const canEvaluate = () => {
        return (selectedPreset.system === SYSTEM_TYPE_CHAINER.key && runsheetId)
            || (selectedPreset.system !== SYSTEM_TYPE_CHAINER.key && collectionId)
    }
    
    useEffect(() => {
        
        if (selectedPreset) {
            
            actions.getAllPresetDocuments(selectedPreset.id)
            actions.getAllPresetEvaluations(selectedPreset.id).then(evals => {
                store.selectedPresetEvaluation.setValue(evals)
                store.preset.setValue(selectedPreset)
                setEvaluations(evals)
            })
            
        }
        
    }, [selectedPreset])
    
    return (
        <CustomModal
            className="max-w-[95vw]"
            open={showDocumentsModal}
            modalHandler={handleClose}>
            <ModalHeader>
                {selectedPreset.name}
                <div className="text-sm text-base-content/50">
                    {selectedPreset.system} ({selectedPreset.vet?.name})
                </div>
            </ModalHeader>
            
            <ModalBody>
                <div className="max-h-[calc(100vh-200px)]">
                    <div className="flex justify-between items-center pb-4">
                        {selectedPreset.system !== SYSTEM_TYPE_CHAINER.key &&
                                <div className="flex gap-4 w-10/12">
                                    <label className="content-center" htmlFor="collection">
                                        Collection to Evaluate
                                    </label>
                                    <select
                                        required={true}
                                        id="collection"
                                        className="select select-bordered select-sm w-6/12"
                                        value={collectionId}
                                        onChange={e => setCollectionId(e.target.value)}>
                                        <option disabled value=""></option>
                                        {collections.filter(col => col.isEval).map(it => (
                                            <option key={it.id} value={it.id}>
                                                {it.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                        
                        {selectedPreset.system === SYSTEM_TYPE_CHAINER.key &&
                                <div className="flex gap-4">
                                    <label className="content-center" htmlFor="runsheet">
                                        Runsheet to Evaluate
                                    </label>
                                    <select
                                        required={true}
                                        id="runsheet"
                                        className="select select-bordered select-sm w-6/12"
                                        value={runsheetId}
                                        onChange={e => setRunsheetId(e.target.value)}>
                                        <option disabled value=""></option>
                                        {runsheets.map(it => (
                                            <option key={it.id} value={it.id}>
                                                {it.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                        <button
                            className='btn btn-secondary self-start'
                            onClick={() => setShowChangeClonedPresetName(true)}
                            disabled={isInserting}>
                                Clone Preset
                        </button>
                    </div>
                    <div className="max-h-[calc(100vh-264px)] overflow-auto">
                        <table className="table table-pin-rows">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Document Name</th>
                                    <th>System</th>
                                    <th>Type</th>
                                    <th>SubType</th>
                                    <th>Description</th>
                                    <th>Relevant Text</th>
                                    <th>Logic</th>
                                    <th>Answer</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {presetDocuments.map(fpd => {
                                    return (
                                        <tr key={fpd.id}>
                                            <td>
                                                {snippet(fpd.id)} <CopyToClipboardButton value={fpd.id} />
                                            </td>
                                            <td>{fpd.document?.name}</td>
                                            <td>{fpd.system}</td>
                                            <td>{fpd.type}</td>
                                            <td>{fpd.subType}</td>
                                            <td>{fpd.description}</td>
                                            <td>{fpd.relevantText}</td>
                                            <td>{fpd.logic}</td>
                                            <td>{fpd.answer}</td>
                                            <td>
                                                <div
                                                    onClick={() => {
                                                        setPresetDocument(fpd)
                                                        setShowEditExampleModal(true)
                                                    }}
                                                    className='cursor-pointer tooltip'
                                                    data-tip="Edit"> <MdOutlineEdit />
                                                </div>
                                            </td>
                                        
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-outline"
                    onClick={handleClose}>
                        Close
                </button>
                
                <div className="divider divider-horizontal m-0"></div>
                
                <button
                    className="btn btn-primary"
                    disabled={!canEvaluate()}
                    onClick={() => {
                        actions.createEval(selectedPreset.id, collectionId, runsheetId)
                        toast.success(`Evaluation started for "${selectedPreset.name}".`)
                        handleClose()
                    }}>
                        Evaluate
                </button>
                
                <Link
                    className="btn btn-primary"
                    disabled={!evaluations.length}
                    to={`/dashboard/example-manager/presets/evaluation-results/${selectedPreset.id}`}>
                        View Evaluations
                </Link>
                <div className="divider divider-horizontal m-0"></div>
                
                <button
                    className="btn btn-secondary"
                    disabled={isInserting}
                    onClick={copyToWorkingTable}>
                        Copy to WorkingTable
                </button>
                <button
                    className="btn btn-primary"
                    disabled={isInserting}
                    onClick={() => {
                        setShowDocumentsModal(false)
                        setShowConfirmationModal(true)
                    }}>Deploy to LiveTable</button>
            </ModalFooter>
        
        
        </CustomModal>
    )
}

export default PresetDocumentsModal
