import {
    MdHomeFilled,
    MdPerson,
    MdOutlinePeople,
    MdGroups,
    MdOutlineCollectionsBookmark,
    MdOutlineContentCopy,
    MdDraw,
    MdOutlineQueue,
    MdLabel,
    MdLocalFireDepartment,
    MdManageSearch,
    MdLogout,
    MdCompareArrows,
    MdPhoneInTalk,
    MdSaveAlt,
    MdTrackChanges,
    MdMoney,
    MdCompare,
    MdReport,
} from 'react-icons/md'
import { FaSink } from 'react-icons/fa'

const primaryMenuItems = [
    {
        label: 'HOME',
        to: '/dashboard',
        Icon: MdHomeFilled,
        className: 'sidebar-text-orange',
    },
    {
        label: 'PROFILE',
        to: '/dashboard/profile',
        Icon: MdPerson,
        className: 'sidebar-text-orange',
    },
    {
        label: 'USERS',
        to: '/dashboard/users',
        Icon: MdOutlinePeople,
        className: 'sidebar-text-orange',
    },
    {
        label: 'ORGANIZATIONS',
        to: '/dashboard/organizations',
        Icon: MdGroups,
        className: 'sidebar-text-orange',
    },
    {
        label: 'COLLECTIONS',
        to: '/dashboard/collections',
        Icon: MdOutlineCollectionsBookmark,
        className: 'sidebar-text-amber',
    },
    {
        label: 'DOCUMENTS',
        to: '/dashboard/documents',
        Icon: MdOutlineContentCopy,
        className: 'sidebar-text-green',
    },
    {
        label: 'ANNOTATORS',
        to: '/dashboard/annotators',
        Icon: MdDraw,
        className: 'sidebar-text-purple',
    },
    {
        label: 'ANNOTATION LABELS',
        to: '/dashboard/labels',
        Icon: MdLabel,
        className: 'sidebar-text-purple',
    },
    {
        label: 'QUEUE',
        to: '/dashboard/queue',
        Icon: MdOutlineQueue,
        className: 'sidebar-text-purple-100',
    },
    {
        label: 'FIREBASE',
        to: '/dashboard/firebase',
        Icon: MdLocalFireDepartment,
        className: 'sidebar-text-rose',
    },
    {
        label: 'ELASTIC SEARCH',
        to: '/dashboard/elasticsearch',
        Icon: MdManageSearch,
        className: 'sidebar-text-sky',
    },
    {
        label: 'COST AND REVENUE',
        to: '/dashboard/costandrevenue',
        Icon: MdMoney,
        className: 'sidebar-text-green',
    },
    {
        label: 'CHECK IMPORTS',
        to: '/dashboard/check-imports',
        Icon: MdSaveAlt,
        className: 'sidebar-text-teal',
    },
    {
        label: 'CHECK MAPPINGS',
        to: '/dashboard/check-mappings',
        Icon: MdCompareArrows,
        className: 'sidebar-text-teal',
    },
    {
        label: 'PAGER DUTY',
        to: '/dashboard/pagerduty',
        Icon: MdPhoneInTalk,
        className: 'sidebar-text-indigo',
    },
    {
        label: 'USER TRACKING',
        to: '/dashboard/user-tracking',
        Icon: MdTrackChanges,
    },
    {
        label: 'TRACKING REPORT',
        to: '/dashboard/tracking-report',
        Icon: MdReport,
    },
    {
        label: 'EXAMPLE MANAGER',
        to: '/dashboard/example-manager',
        Icon: MdCompare,
    },
    {
        id: 8999,
        divider: true,
    },
    {
        id: 9998,
        label: 'KITCHEN SINK',
        to: '/kitchensink',
        Icon: FaSink,
        className: 'sidebar-text-slate-400',
    },
    {
        id: 9999,
        label: 'LOGOUT',
        Icon: MdLogout,
        className: 'sidebar-text-red',
    },
]

for (let i = 0; i < primaryMenuItems.length; i++) {
    
    if (!primaryMenuItems[i].id)
        primaryMenuItems[i].id = i + 1
    
}

export default primaryMenuItems
