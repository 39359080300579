import ParseAPI from '@api/ParseAPI'
import config from '@/config'
import * as store from '@store'
import { themes, COMPAT_ERRORS, AppCheckStatus, appVersionCheckTimeout } from '@constants'
import { deepmergeWithArrays } from '@utils'
import buildInfo from '@/build.json'
import dayjs from 'dayjs'

export const fetchRemoteAppVersion = async () => {
    
    try {
        
        // Use this for easier local testing
        // const res = await fetch(`https://develop.parseai.co/build.json?${Date.now()}`)
        
        const res = await fetch(`${location.origin}/build.json?${Date.now()}`)
        const json = await res.json()
        
        const localDate = dayjs(buildInfo.date).format('YYYY-MM-DD')
        const remoteDate = dayjs(json.date).format('YYYY-MM-DD')
        
        const appVersionStatus = localDate === remoteDate
            ? AppCheckStatus.UpToDate
            : AppCheckStatus.OutOfDate
        
        if (process.env.NODE_ENV === 'development')
            console.log('useAppVersionCheck', localDate, 'vs', remoteDate, '=',
                appVersionStatus === AppCheckStatus.UpToDate
                    ? 'UpToDate' : 'OutOfDate')
        
        store.appCheckStatus.setValue(appVersionStatus)
        
    } catch (e) {
        
        console.error('useAppVersionCheck', e)
        
        // @todo can't really assume the app is up to date,
        // since it might just be a network error. need to rethink
        // store.appCheckStatus.setValue(AppCheckStatus.OutOfDate)
        
    }
    
}

export const checkRemoteAppVersion = async () => {
    
    try {
        
        await Promise.race([
            fetchRemoteAppVersion(),
            new Promise(resolve => setTimeout(resolve, appVersionCheckTimeout)),
        ])
        
    } catch (e) {
        
        console.error('useAppVersionCheck: failed to fetch remote version', e)
        
        // @todo can't really assume the app is up to date,
        // since it might just be a network error. need to rethink
        // store.appCheckStatus.setValue(AppCheckStatus.OutOfDate)
        
    }
    
}

export const switchThemeNext = () => {
    
    const theme = store.theme.getValue()
    const themeIndex = themes.findIndex(it => it.key === theme.key) || 0
    const nextTheme = themes[themeIndex + 1] || themes[0]
    
    store.theme.setValue(nextTheme)
    
}

export const fetchParseConfig = async () => {
    
    if (store.isFetchingParseConfig.getValue()) return
    
    store.isFetchingParseConfig.setValue(true)
    
    const res = await ParseAPI.get('/')
    
    try {
        
        const overrides = process.env.FIREBASE_REMOTE_CONFIG_OVERRIDES ?? {}
        
        if (overrides)
            console.log('Using remote config overrides', overrides || {})
        
        res.data.remoteConfig = deepmergeWithArrays(
            res.data.remoteConfig,
            overrides || {}, 
            { clone: false },
        )
        
    } catch (e) {
        // NOOP
    }
    
    store.apiConfig.setValue(res.data ?? {})
    
    store.isFetchingParseConfig.setValue(false)
    
    return res.data
    
}

export const identifyHubspotUser = () => {
    
    if (!config.enableHubSpot) return
    
    if (!window._hsq)
        return console.warn('HubSpot identifyUser: window._hsq not found')
    
    const user = store.user.getValue()
    
    if (!user?.email)
        return console.warn('HubSpot identifyUser: user email not found')
    
    window._hsq.push(['identify', {
        email: user.email,
    }])
    
    console.log('HubSpot identifyUser: user', user.email, '- success')
    
}

// Compatibility check for Grammarly freezing the site on textarea fields
export const checkCompatibility = () => {
    
    // eslint-disable-next-line no-restricted-globals
    const grammerlyEls = document.querySelectorAll('grammarly-extension')
    const errors = []
    
    if (grammerlyEls.length)
        errors.push(COMPAT_ERRORS.GRAMMARLY)
    
    if (errors.length)
        store.compatErrors.setValue(errors)
    
}
