import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { omit, createQueryString } from '@utils'
import { buildPartyInterestData } from '@/components/tract/Runsheet/Runsheet.lib'

const log = new Logger('actions/runsheets')

const storeKey = 'runsheets'

export const checkRunsheetLock = async runsheetDocumentJoinIds => {
    try {
        
        const res = await ParseAPI.post('runsheets/documents/locked', {
            ids: runsheetDocumentJoinIds,
        })
        const unlockedDocs = res.data
        
        
        if (unlockedDocs.length) {
            const copyRunsheet = { ...store.currentRunsheet.getValue() }
            
            for (let i = 0; i < copyRunsheet?.rdj.length; i++) {
                const foundUnlockedDoc = unlockedDocs.find(ud => ud.id === copyRunsheet?.rdj[i].id)
                
                if (foundUnlockedDoc) {
                    copyRunsheet.rdj[i] = {
                        ...copyRunsheet?.rdj[i],
                        value: foundUnlockedDoc.value,
                        document: foundUnlockedDoc.document,
                        locked: false,
                    }
                }
            }
            
            copyRunsheet.rdj = [...copyRunsheet.rdj]
            store.currentRunsheet.setValue(copyRunsheet)
        }
    } catch (e) {
        log.e(e)
    }
}

export const fetchRunsheets = async () => {
    
    try {
        
        const res = await ParseAPI.get('runsheets')
        
        store.runsheets.setValue(res.data)
        store.titleBreakdown.setValue(null)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const fetchRunsheetById = async id => {
    
    try {
        
        const res = await ParseAPI.get(`runsheets/${id}`)
        
        const runsheet = res.data
        
        runsheet.rdj?.forEach(d => {
            if (d.value) {
                d.value.grantors = d.value.grantors.map(gtor => buildPartyInterestData(gtor))
                d.value.grantees = d.value.grantees.map(gtee => buildPartyInterestData(gtee))
            }
        })
        
        store.currentRunsheet.setValue(runsheet)
        
        // store.arrayUpsertById(storeKey, runsheet, (prev, next) => ({
        //     ...prev,
        //     ...next,
        // }))
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const fetchRunsheetsByTractId = async tractId => {
    
    try {
        
        const res = await ParseAPI.get(`runsheets/bytractid/${tractId}`)
        
        store.runsheets.setValue(res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const copyRunsheet = async (runsheetId, runsheetName, values) => {
    
    try {
        
        const res = await ParseAPI.post(`runsheets/${runsheetId}/copy`, {
            runsheetName,
            values,
        })
        
        const objectToAppend = {
            
            assignedTo: res.data.assignedTo,
            certDate: res.data.certDate,
            id: res.data.id,
            name: res.data.name,
            numOfDocs: res.data.numOfDocs,
            status: res.data.status,
            statusDate: res.data.statusDate,
            
        }
        
        store.runsheets.setValue([ objectToAppend, ...store.runsheets.getValue()])
        
        return objectToAppend
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const copyDocumentsToRunsheet = async (fromRunsheetId, toRunsheetId, documentsToCopy) => {
    
    try {
        
        await ParseAPI.post(`runsheets/${fromRunsheetId}/copyTo/${toRunsheetId}`, {
            documentsToCopy,
        })
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const updateRunsheet = async (id, runsheet, rest) => {
    
    try {
        
        const updatedRunsheet = { ...runsheet, ...rest }
        const res = await ParseAPI.post(`runsheets/${id}`, {
            runsheet: updatedRunsheet,
        })
        
        store.arrayUpdateById('runsheets', res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const deleteRunsheet = async id => {
    
    try {
        
        await ParseAPI.delete(`runsheets/${id}`)
        
        const runsheets = store.runsheets.getValue()
        
        if (runsheets) {
            const updatedRunsheet = runsheets.filter(d => d.id !== id)
            
            store.runsheets.setValue(updatedRunsheet)
        }
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

/**
 * Adds a document to a runsheet
 * 
 * @param {StripeBalancePurchaseAccountType} fundingSource
 * @param {Object} payload
 * @param {UUID} payload.runsheetId
 * @param {UUID[]} payload.documentIds
 * @param {UUID} [payload.runsheetTabId]
 * @returns {Promise<UUID[]>}
 */
export const addRunsheetDocument = async (fundingSource, payload) => {
    
    try {
        const currentRunsheet = store.currentRunsheet.getValue()
        
        const response = await ParseAPI.put('runsheets/documents', {
            fundingSource,
            ...payload,
        })
        
        response.data.runsheets.forEach(result => {
            
            if (result.id === currentRunsheet.id) {
                store.currentRunsheet.setValue(result)
            }
            
            store.arrayUpsertById(storeKey, result, (prev, next) => ({
                ...prev,
                ...next,
            }))
        })
        
        return response.data.documentIds
    } catch (e) {
        log.e(e)
        throw e
    }
    
}

export const assignTractsToRunsheet = async (runsheetId, tractId) => {
    const res = await ParseAPI.post(`runsheets/${runsheetId}/assign/tracts`, {
        tractId,
    })
    
    const currentRunsheet = store.currentRunsheet.getValue()
    
    if (currentRunsheet) {
        
        currentRunsheet.tract = res.data
        currentRunsheet.tractId = res.data.id
        store.currentRunsheet.setValue({ ...currentRunsheet })
    }
}

export const updateRunsheetValue = async (runsheetValueId, runsheetId, documentId, field, value) => {
    try {
        
        const newValue = await ParseAPI.patch(`runsheets/value/${runsheetValueId}`, {
            field,
            value,
            runsheetId,
            documentId,
        })
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const replaceIdx = currentRunsheet.rdj.findIndex(r => r.value.id === runsheetValueId)
            
            currentRunsheet.rdj = [...currentRunsheet.rdj]
            
            currentRunsheet.rdj[replaceIdx] = {
                ...currentRunsheet.rdj[replaceIdx],
                value: { ...currentRunsheet.rdj[replaceIdx].value, [field]: newValue.data },
            }
            
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
    } catch (error) {
        
        log.e(error)
        
    }
}

export const deleteDocumentFromRunsheet = async (runsheetId, idList) => {
    
    try {
        
        return await ParseAPI.post(`runsheets/remove/${runsheetId}`, { idList })
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const searchRunsheetDocument = async ({
    collectionId,
    query,
    offset = 0,
    limit = 20,
}) => {
    
    try {
        
        const res = await ParseAPI.post(`document/search/${collectionId}`, {
            query,
            offset,
            limit,
        })
        
        // @todo explore this once full-text search is hooked up
        // const res = await ParseAPI.get(`document/searchv2/${collectionId}`, query)
        
        store.documents.setValue(res.data)
        
    } catch (e) {
        console.error(e)
        log.e(e)
        
    }
    
}

export const searchRunsheetIndex = async ({
    collectionId,
    query,
    offset = 0,
    limit = 20,
}) => {
    
    try {
        
        const res = await ParseAPI.post(`index/values/search/${collectionId}`, {
            query,
            offset,
            limit,
        })
        
        const documents = res.data.rows.reduce((acc, doc) => ({
            ...acc,
            [doc.id]: omit(doc, 'indexValues'),
        }), {})
        
        store.documents.setValue({
            count: res.data.count,
            rows: Object.values(documents),
        })
        
    } catch (e) {
        console.error(e)
        log.e(e)
        
    }
    
}

// @todo @deprecated
export const fetchExportTemplatesByRunsheetId = async (runsheetId, type) => {
    
    try {
        
        const paramsObj = {
            type,
        }
        
        const params = createQueryString(paramsObj)
        
        const res = await ParseAPI.get(`runsheets/${runsheetId}/templates${params}`)
        
        return res?.data ?? []
        
    } catch (e) {
        
        log.e('fetchExportTemplatesByRunsheetId', e)
        return []
        
    }
    
}

export const handleRunsheetAnnotationProcess = async (runsheetId, documentId, favorMachineAnnotation) => {
    const res = await ParseAPI.post(`runsheets/${runsheetId}/enhance`, {
        documentId,
        favorMachineAnnotation,
    })
    
    return res?.data ?? []
}

export const upsertPartyAddress = async (partyId, address) => {
    try {
        await ParseAPI.post(`runsheets/${partyId}/address`, { address })
    } catch (err) {
        log.e('upsertPartyAddress', err)
    }
}

export const getDocumentPlotsByRunsheet = async runsheetId => {
    try {
        
        const res = await ParseAPI.get(`documentPlots/${runsheetId}`)
        
        return res.data
    } catch (e) {
        
        log.e('getDocumentPlotsByRunsheet', e)
        return []
        
    }
}

export const updatePlotPosition = async (documentId, runsheetLegalDescriptionId, coordinates) => {
    try {
        
        const res = await ParseAPI.put(`documentPlots/${documentId}`, { runsheetLegalDescriptionId, coordinates })
        
        return res.data
    } catch (e) {
        
        log.e('updatePlotsPosition', e)
        return []
        
    }
}

export const exportTract = async (tractId, { runsheetId, runsheetTabId, formatType }) => {
    
    const paramsObj = {
        runsheetId,
        runsheetTabId,
        formatType,
    }
    
    const params = createQueryString(paramsObj)
    
    try {
        
        return await ParseAPI.get(`/tracts/export/${tractId}${params}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
}

export const saveRunsheetLegals = async (runsheetValueId, legals) => {
    try {
        
        const res = await ParseAPI.post(`runsheetLegalDescriptions/${runsheetValueId}`, { legals })
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const replaceIdx = currentRunsheet.rdj.findIndex(r => r.value.id === runsheetValueId)
            
            currentRunsheet.rdj = [...currentRunsheet.rdj]
            
            currentRunsheet.rdj[replaceIdx] = {
                ...currentRunsheet.rdj[replaceIdx],
                value: { ...currentRunsheet.rdj[replaceIdx].value, legalDescriptions: res.data },
            }
            
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
}

/**
 * Exports a runsheet
 * @param {UUID} runsheetId
 * @param {UUID[]} documentIds
 * @param {UUID} runsheetTabId
 * @param {Object} params
 * @param {String[]} params.selectedTypes
 * @param {UUID} params.templateId
 * @param {String} params.tabToExport
 * @returns {Promise<ExportPayload>}
 */
export const exportRunsheet = async (runsheetId, documentIds, runsheetTabId, params) => {
    
    try {
        
        const res = await ParseAPI.post('/pdfs', {
            runsheetId,
            idList: documentIds,
            runsheetTabId,
            ...params,
        })
        
        // console.log('exportRunsheet', res.data)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
