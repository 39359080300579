import { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import * as actions from '@actions'
import { RECORD_TYPES } from '@constants/annotations'
import { toast } from 'react-toastify'

const NewLabelRow = ({
    labelsType,
}) => {
    
    const refType = useRef()
    const refLabel = useRef()
    const refDescription = useRef()
    
    const [type, setType] = useState(labelsType ?? '')
    const [label, setLabel] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [description, setDescription] = useState('')
    
    const isFormValid = useMemo(() => {
        if (!type?.length) return false
        if (!RECORD_TYPES.includes(type)) return false
        if (!label?.length) return false
        return true
    }, [type, label])
    
    const onCreateLabelClick = useCallback(async () => {
        
        if (!type?.length || !RECORD_TYPES.includes(type))
            return console.log('invalid type') || refType.current?.focus()
        
        if (!label?.length)
            return refLabel.current?.focus()
        
        try {
            
            await actions.createLabel({ type, label, displayName, description })
            await actions.fetchAllLabels()
            
            setLabel('')
            setDescription('')
        } catch (e) {
            
            console.error('onCreateLabelClick', e)
            toast.error('Failed to create label')
            
        }
        
    }, [type, label, displayName, description])
    
    useEffect(() => { setType(labelsType ?? '') }, [labelsType])
    
    return (
        
        <form
            onSubmit={e => {
                e.preventDefault()
                onCreateLabelClick()
            }}
            className="flex items-end gap-2 p-4 bg-base-200 rounded-t">
            
            <div className="flex flex-col gap-2">
                {labelsType?.length > 0 ? labelsType : (
                    <select
                        ref={refType}
                        className="select select-bordered select-sm"
                        onChange={e => setType(e.target.value)}>
                        <option value="">Type</option>
                        {RECORD_TYPES.map(it => (
                            <option
                                key={`new-label-type-${it}`}
                                value={it}>
                                {it}
                            </option>
                        ))}
                    </select>
                )}
                <input
                    ref={refLabel}
                    type="text"
                    autoComplete="off"
                    className="input input-bordered"
                    value={label}
                    placeholder="Label name"
                    onChange={e => setLabel(e.target.value ?? '')} />
            
            </div>
            <div className="flex flex-col gap-2 flex-1 items-start">
                <input
                    type="text"
                    autoComplete="off"
                    className="input input-bordered w-1/2"
                    value={displayName}
                    placeholder="Display name"
                    onChange={e => setDisplayName(e.target.value ?? '')} />
                
                <input
                    ref={refDescription}
                    type="text"
                    autoComplete="off"
                    className="input input-bordered w-full"
                    placeholder="Description"
                    value={description}
                    onChange={e => setDescription(e.target.value ?? '')}>
                </input>
            </div>
            <button
                className="btn-create-label btn btn-outline-primary"
                disabled={!isFormValid}>
                CREATE LABEL
            </button>
        </form>
        
    )
    
}

export default NewLabelRow
