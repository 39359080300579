import ModalBody from '@/components/shared/CustomModal/ModalBody'
import ModalHeader from '@/components/shared/CustomModal/ModalHeader'
import Flatpickr from 'react-flatpickr'
import dayjs from 'dayjs'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { createSyntheticDownload } from '@/utils'
import * as actions from '$actions'
import { MdFileDownload } from 'react-icons/md'

const TrackingReport = () => {
    const date = dayjs()
    const [radioDate, setRadioDate] = useState('dateMonth30')
    const [generateReport, setGenerateReport] = useState(false)
    const [selectedMonth, setSelectedMonth] = useState(date)
    const [initialDate, setInitialDate] = useState('')
    const [finalDate, setFinalDate] = useState('')
    
    const updateDates = newRadioDate => {
        const startOfMonth = selectedMonth?.startOf('month')
        const endOfMonth = selectedMonth?.endOf('month')
        
        switch (newRadioDate || radioDate) {
            case 'dateMonth1/15': 
                setInitialDate(startOfMonth?.format('YYYY-MM-DD'))
                setFinalDate(startOfMonth?.add(14, 'day').format('YYYY-MM-DD'))
                break
            case 'dateMonth16/30': 
                setInitialDate(startOfMonth?.add(15, 'day').format('YYYY-MM-DD'))
                setFinalDate(endOfMonth?.format('YYYY-MM-DD'))
                break
            case 'dateMonth30': 
                setInitialDate(startOfMonth?.format('YYYY-MM-DD'))
                setFinalDate(endOfMonth?.format('YYYY-MM-DD'))
                break
            default:
                setInitialDate('')
                setFinalDate('')
                break
        }
    }
    
    useEffect(() => {
        updateDates('dateMonth30') 
    }, [selectedMonth])
    
    const submitForm = async event => {
        event.preventDefault()
        
        if (!radioDate || !initialDate || !finalDate) {
            toast.error('Please select all options before generating the report.')
            return
        }
        
        setGenerateReport(true)
        
        const res = await actions.fetchTrackingReport(initialDate, finalDate)
        
        if (res) {
            createSyntheticDownload(res.data, res.headers['content-type'], res.headers['content-name'])
            toast.success('Tracking Report Success')
        } else {
            toast.error('Failed to export Title Chain List, please try again.')
        }
        
        setGenerateReport(false)
        
    }
    
    return (
        <div className=" flex flex-col items-center justify-center bg-base-200
        align-middle p-6 gap-4 rounded-lg m-auto w-90">
            <ModalHeader> Tracking Report </ModalHeader>
            <ModalBody>
                <form onSubmit={submitForm} className="flex flex-col p-4 gap-4">
                    <div className="form-group flex-1 mb-0">
                        <p>Select Date</p>
                        <Flatpickr
                            className="input input-bordered flatpickr-input"
                            name="select date"
                            placeholder="Month/year"
                            defaultValue={selectedMonth?.format('MMMM')}
                            options={{
                                dateFormat: 'M Y',
                                plugins: [new monthSelect()],
                            }}
                            onChange={date => {
                                const newMonth = dayjs(date[0])
                                
                                setSelectedMonth(newMonth)
                                updateDates()
                                setRadioDate()
                            }}/>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-2 items-center">
                            <input 
                                id="dateMonth1/15"
                                value="dateMonth1/15"
                                name='dateMonth'
                                type="radio"
                                checked={radioDate === 'dateMonth1/15'}
                                className="radio radio-primary"
                                onChange={e => {
                                    const value = e.target.value
                                    
                                    setRadioDate(value)
                                    updateDates(value)
                                }}/>
                            <label htmlFor="dateMonth1/15"> 
                                1/{selectedMonth ? selectedMonth?.format('MMMM') : 'month'} - 15/
                                {selectedMonth ? selectedMonth?.format('MMMM') : 'month'}
                            </label>
                        </div>
                        <div className="flex gap-2 items-center">
                            <input 
                                id="dateMonth16/30"
                                value="dateMonth16/30"
                                name='dateMonth'
                                type="radio"
                                checked={radioDate === 'dateMonth16/30'}
                                className="radio radio-primary"
                                onChange={e => {
                                    const value = e.target.value
                                    
                                    setRadioDate(value)
                                    updateDates(value)
                                }}/>
                            <label htmlFor="dateMonth16/30"> 
                                16/{selectedMonth ? selectedMonth?.format('MMMM') : 'month'} -&nbsp;
                                {selectedMonth ? selectedMonth?.endOf('month').format('D') : '[28/30/31]'}
                                /{selectedMonth ? selectedMonth?.format('MMMM') : 'month'} 
                            </label>
                        </div>
                        <div className="flex gap-2 items-center">
                            <input 
                                id="dateMonth30"
                                value="dateMonth30"
                                name='dateMonth'
                                type="radio"
                                
                                checked={radioDate === 'dateMonth30'}
                                className="radio radio-primary"
                                onChange={e => {
                                    const value = e.target.value
                                    
                                    setRadioDate(value)
                                    updateDates(value)
                                }}/>
                            <label htmlFor="dateMonth30"> 
                                1/{selectedMonth ? selectedMonth?.format('MMMM') : 'month'} -&nbsp;
                                {selectedMonth ? selectedMonth?.endOf('month').format('D') : '[28/30/31]'}
                                /{selectedMonth ? selectedMonth?.format('MMMM') : 'month'} 
                            </label>
                        </div>
                    </div>
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={generateReport || !selectedMonth}>
                        <MdFileDownload/> Generate report
                    </button>
                </form>
            </ModalBody>
        
        </div>    
    )
}

export default TrackingReport