import config from '@/config'
import dashboardRoutes from '$routes'
import { ROUTE_PATHS } from '@constants'

export const publicRoutes = [
    {
        path: ROUTE_PATHS.SIGNIN,
        component: 'SignIn',
        excludeTopNav: true,
        title: 'Sign In',
    },
    {
        path: ROUTE_PATHS.SIGNUP,
        component: 'SignUp',
        excludeTopNav: true,
        title: 'Sign Up',
    },
    {
        path: ROUTE_PATHS.EMAIL_SENT,
        component: 'EmailSent',
        excludeTopNav: true,
        title: 'Sign Up Complete',
    },
    {
        path: ROUTE_PATHS.RESEND_EMAIL,
        component: 'ResendEmail',
        excludeTopNav: true,
        title: 'Resend Email',
    },
    {
        path: ROUTE_PATHS.FORGOT_PASSWORD,
        component: 'ForgotPassword',
        excludeTopNav: true,
        title: 'Forgot Password',
    },
    {
        path: ROUTE_PATHS.RESET_PASSWORD,
        component: 'ResetPassword',
        excludeTopNav: true,
        title: 'Reset Password',
    },
    {
        path: ROUTE_PATHS.RESET_PASSWORD_EMAIL_SENT,
        component: 'ResetPasswordEmailSent',
        excludeTopNav: true,
        title: 'Reset Password',
    },
    {
        path: ROUTE_PATHS.SET_PASSWORD,
        component: 'SetPassword',
        excludeTopNav: true,
        title: 'Set Password',
    },
    {
        path: ROUTE_PATHS.SET_BILLING,
        component: 'SetBilling',
        excludeTopNav: true,
        title: 'Set Billing',
    },
    {
        path: ROUTE_PATHS.SET_ORGANIZATIONS,
        component: 'SignupOrgFlow',
        excludeTopNav: true,
        title: 'Signup Organization',
    },
]

export const privateRoutes = [
    
    // DEBUG
    {
        path: ROUTE_PATHS.KITCHEN_SINK,
        section: 'debug',
        component: 'KitchenSink',
        title: 'Debug - Kitchen Sink',
        childRoutes: [
            {
                path: '',
                section: 'debug',
                excludeTopNav: true,
                component: 'KSHome',
                title: 'Debug - Kitchen Sink',
            },
            {
                path: ROUTE_PATHS.KITCHEN_SINK_RENDER_PDF,
                section: 'debug',
                excludeTopNav: true,
                component: 'KSRenderPdf',
                title: 'Render PDF - Debug - Kitchen Sink',
            },
            {
                path: ROUTE_PATHS.KITCHEN_SINK_LABEL_STUDIO,
                section: 'debug',
                excludeTopNav: true,
                component: 'KSLabelStudio',
                title: 'LabelStudio - Debug - Kitchen Sink',
            },
            {
                path: ROUTE_PATHS.KITCHEN_SINK_TYPOGRAPHY,
                section: 'debug',
                excludeTopNav: true,
                component: 'KSTypography',
                title: 'Typography - Debug - Kitchen Sink',
            },
            {
                path: ROUTE_PATHS.KITCHEN_SINK_REALTIME,
                section: 'debug',
                excludeTopNav: true,
                component: 'KSRealtime',
                title: 'Realtime - Debug - Kitchen Sink',
            },
            {
                path: ROUTE_PATHS.KITCHEN_SINK_CLIENT_PDF_SPLIT,
                section: 'debug',
                excludeTopNav: true,
                component: 'KSClientPdfSplit',
                title: 'Client PDF Split - Debug - Kitchen Sink',
            },
        ],
    },
    
    {
        path: ROUTE_PATHS.COLLECTIONS,
        section: 'collections',
        component: 'Collections',
        title: 'Collections - Personal',
    },
    {
        path: ROUTE_PATHS.HALFILE,
        section: 'halfile',
        component: 'HalfileCounties',
        title: 'Collections - HalFILE',
    },
    {
        path: ROUTE_PATHS.TRACTS,
        section: 'tracts',
        component: 'Tracts',
        title: 'Tracts',
    },
    {
        path: ROUTE_PATHS.TRACT_OWNERSHIP,
        section: 'tracts',
        component: 'TractOwnership',
        title: 'Tract - Ownership',
    },
    {
        path: ROUTE_PATHS.RUNSHEETS,
        section: 'runsheets',
        component: 'Runsheets',
        title: 'Runsheets',
    },
    {
        path: ROUTE_PATHS.TRACT_DETAIL,
        section: 'tracts',
        component: 'TractView',
        title: 'Tract',
    },
    {
        path: ROUTE_PATHS.RUNSHEET_DETAIL,
        section: 'runsheets',
        component: 'Runsheet',
        title: 'Runsheet',
    },
    {
        path: ROUTE_PATHS.RUNSHEET_TITLE_BREAKDOWN,
        section: 'runsheets',
        component: 'TitleBreakdown',
        title: 'Runsheet - Title Breakdown',
    },
    {
        path: ROUTE_PATHS.RUNSHEET_DETAIL_TRACT_ID,
        section: 'runsheets',
        component: 'Runsheet',
        title: 'Runsheet',
    },
    {
        path: ROUTE_PATHS.VIEW_DOCUMENT_ID,
        section: 'collections',
        component: 'DocumentView',
        title: 'Document',
    },
    {
        path: ROUTE_PATHS.PROJECTS,
        section: 'projects',
        component: 'Projects',
        title: 'Projects',
    },
    {
        path: ROUTE_PATHS.PROJECT_DETAIL,
        section: 'projects',
        component: 'ProjectView',
        title: 'Project',
    },
    {
        path: ROUTE_PATHS.COLLECTION_DETAIL,
        section: 'collections',
        component: 'CollectionComponent',
        title: 'Collection',
    },
    {
        path: ROUTE_PATHS.COLLECTION_DETAIL_ACTIVE_TAB,
        section: 'collections',
        component: 'CollectionComponent',
        title: 'Collection',
    },
    {
        path: ROUTE_PATHS.HALFILE_DETAIL,
        section: 'halfile',
        component: 'HalfileCountySearch',
        title: 'Collection - HalFILE',
    },
    {
        path: ROUTE_PATHS.CHECKS,
        section: 'checks',
        component: 'Checks',
        title: 'Checks',
    },
    {
        path: ROUTE_PATHS.CHECK_OPERATORS,
        section: 'operators',
        component: 'Operators',
        title: 'Operators',
    },
    {
        path: ROUTE_PATHS.CHECK_OPERATOR_DETAIL,
        section: 'operators',
        component: 'OperatorDetail',
        title: 'Operator',
    },
    {
        path: ROUTE_PATHS.CHECK_WELLS,
        section: 'wells',
        component: 'Wells',
        title: 'Wells',
    },
    {
        path: ROUTE_PATHS.CHECK_WELL_DETAIL,
        section: 'wells',
        component: 'WellDetail',
        title: 'Well',
    },
    {
        path: ROUTE_PATHS.CHECK_REPORTS,
        section: 'checks',
        component: 'CheckReports',
        title: 'Check Reports',
    },
    {
        path: ROUTE_PATHS.CHECK_PORTFOLIO,
        section: 'checks',
        component: 'Portfolio',
        title: 'Portfolio',
    },
    {
        path: ROUTE_PATHS.CHECK_PREVIEW,
        section: 'checks',
        component: 'CheckPreview',
        title: 'Check Preview',
        excludeTopNav: true,
    },
    {
        path: ROUTE_PATHS.CHECK_PREVIEW_BY_PAGE,
        section: 'checks',
        component: 'CheckPreview',
        title: 'Check Preview',
        excludeTopNav: true,
    },
    {
        path: ROUTE_PATHS.CHECK_DETAIL,
        section: 'checks',
        component: 'CheckDetails',
        title: 'Check Details',
    },
    {
        path: ROUTE_PATHS.CHECK_DETAIL_EDIT,
        section: 'checks',
        component: 'CheckDetailsEdit',
        title: 'Check Details',
    },
    {
        path: ROUTE_PATHS.IAM,
        section: 'iam',
        component: 'IAM',
        title: 'IAM',
    },
    {
        path: ROUTE_PATHS.ATR,
        section: 'atr',
        component: 'ATR',
        title: 'ATR',
    },
    {
        path: ROUTE_PATHS.SETTINGS,
        section: 'settings',
        component: 'Settings',
        title: 'Settings',
        childRoutes: [
            {
                path: '',
                section: 'settings',
                excludeTopNav: true,
                component: 'PersonalInfo',
                title: 'Personal Info',
                childRoutes: [
                    {
                        path: ROUTE_PATHS.EMAIL,
                        section: 'settings',
                        component: 'PersonalInfo',
                        title: 'Personal Info',
                    },
                ],
            },
            {
                path: ROUTE_PATHS.ORGS,
                section: 'settings',
                excludeTopNav: true,
                component: 'Organizations',
                title: 'Organizations',
            },
            {
                path: ROUTE_PATHS.ORG_DETAIL,
                section: 'settings',
                excludeTopNav: true,
                component: 'Organization',
                title: 'Organization',
                childRoutes: [
                    {
                        path: '',
                        section: 'settings',
                        excludeTopNav: true,
                        component: 'Members',
                        title: 'Organization - Members',
                    },
                    {
                        path: ROUTE_PATHS.ORG_BILLING,
                        section: 'settings',
                        excludeTopNav: true,
                        component: 'OrgBilling',
                        title: 'Organization - Billing',
                    },
                    {
                        path: ROUTE_PATHS.TEMPLATES,
                        section: 'settings',
                        excludeTopNav: true,
                        component: 'Templates',
                        title: 'Organization - Templates',
                    },
                    {
                        path: ROUTE_PATHS.TEMPLATE_DETAIL,
                        section: 'settings',
                        excludeTopNav: true,
                        component: 'Template',
                        title: 'Organization - Template',
                    },
                ],
            },
            {
                path: ROUTE_PATHS.HISTORY,
                section: 'settings',
                excludeTopNav: true,
                component: 'History',
                title: 'History',
            },
            {
                path: ROUTE_PATHS.BILLING,
                section: 'settings',
                excludeTopNav: true,
                component: 'BillingComponent',
                title: 'Billing',
            },
            {
                path: ROUTE_PATHS.PURCHASES,
                section: 'settings',
                excludeTopNav: true,
                component: 'Purchases',
                title: 'Purchases',
            },
        ],
    },
    {
        path: ROUTE_PATHS.PARTNERS,
        section: 'partners',
        component: 'Partners',
        title: 'Partners',
    },
    {
        path: ROUTE_PATHS.PARTNERS_DOCUMENTS,
        section: 'partners',
        component: 'PartnersDocuments',
        title: 'Partners - Documents',
    },
    {
        path: ROUTE_PATHS.PARTNERS_CHECKS,
        section: 'partners',
        component: 'PartnersChecks',
        title: 'Partners - Checks',
    },
    {
        path: ROUTE_PATHS.PARTNERS_ANNOTATE_DOCUMENT_ID,
        section: 'partners',
        component: 'PartnersAnnotate',
        title: 'Partners - Annotate',
    },
    {
        path: ROUTE_PATHS.PARTNERS_ANNOTATE_CHECK_ID,
        section: 'partners',
        component: 'PartnersAnnotate',
        title: 'Partners - Annotate',
    },
    
]

if (config.enableInternalDashboard)
    dashboardRoutes.forEach(it => privateRoutes.push(it))
