import { createWire } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys } from '@constants'

// Annotation labels + their colors
export const labels = createWire([])

export const labelStudioShowAllLabelsChecked = createPersistedWire(keys.labelStudioShowAllLabelsChecked, false)

export const adminLabelStudioShowPrivateLabelsChecked = createPersistedWire(
    keys.adminLabelStudioShowPrivateLabelsChecked, false)

// En/disable wider document view in Label Studio
export const showExpandedAnnotationView = createPersistedWire(keys.showExpandedAnnotationView, true)

export const checkedProcessDocument = createPersistedWire(keys.processDocument, {})