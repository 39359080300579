import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import * as store from '@store'

const log = new Logger('actions/atr')

export const getPackets = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr')
        
        store.atrPackets.setValue(res.data)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const atrStatuses = async packetIds => {
    
    try {
        
        const res = await ParseAPI.post('atr/status', {
            ids: packetIds,
        })
        
        const pkts = res.data
        
        const packets = store.atrPackets.getValue()
        
        const newPackets = packets.map(p => {
            const foundPkt = pkts.find(pkt => pkt.id === p.id)
            
            if (foundPkt)
                return { ...p, ...foundPkt }
            
            return p
        })
        
        store.atrPackets.setValue(newPackets)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
    
}

export const getFileList = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr/folders')
        
        store.atrDirectories.setValue(res.data)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const processAtrFile = async directory => {
    
    try {
        
        const res = await ParseAPI.post('atr', directory)
        
        const packets = store.atrPackets.getValue()
        
        store.atrPackets.setValue([ res.data, ...packets ])
        
    } catch (error) {
        log.e(error)
        throw error
    }
}
