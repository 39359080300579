import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import * as actions from '$actions'
import cn from 'classnames'
import { MdAdd } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import { useSearchParams } from 'react-router-dom'
import ConfirmationModal from '@/components/shared/ConfirmationModal'
import Toggle from '$components/Forms/Toggle'
import { ST_CHAINER_KEY } from '@/constants/exampleManager'


const CHAINER_DEFINITION = [
    { key: 'get_document_content', definition: '' },
    { key: 'get_current_ownership', definition: '' },
    { key: 'update_ownership', definition: '' },
]

const DEFAULT_DEFINITION = { key: '', displayName: '', definition: '', visible: true }

const InstructionAndDefinitionModal = ({
    
    showInstructionDefinitionModal,
    setShowInstructionDefinitionModal,
    system,
    templateId,
    
}) => {
    
    const [, setSearchParams] = useSearchParams()
    
    const systemTemplates = useWireValue(store.systemTemplates)
    
    const activeSystemTemplates = systemTemplates.filter(sys => sys.system === system)
    const selectedTemplate = activeSystemTemplates.find(tmp => tmp.id === templateId)
    
    const [shouldDeleteTemplate, setShouldDeleteTemplate] = useState(false)
    const [templateName, setTemplateName] = useState(selectedTemplate?.name)
    const [instruction, setInstruction] = useState(selectedTemplate?.instructions || '')
    // removing base template code but leaving here case its necessary in the future
    // const [baseTemplate, setBaseTemplate] = useState(selectedTemplate?.baseTemplate || false)
    
    const [definitions, setDefinitions] = useState([DEFAULT_DEFINITION])
    const [chainerConstants, setChainerConstants] = useState(CHAINER_DEFINITION)
    
    const deleteTemplate = async () => {
        
        try {
            
            await actions.deleteTemplate(templateId)
            
            setSearchParams(prev => {
                
                prev.set('templateId', activeSystemTemplates[0].id)
                
                return prev
            })
            
        } catch (err) {
            
            console.log(err)
            
        }
        
        setShowInstructionDefinitionModal(false)
    }
    
    const createDefinition = async () => {
        
        const defToInsert = definitions.filter(d => d.key)
        
        if (system !== 'chainer') {
            
            const { definitions: savedDefinitions } = await actions.saveInstructionAndDefinitions(
                instruction, templateName, /*baseTemplate*/ null, defToInsert, system, templateId)
            
            if (!savedDefinitions.length) {
                setDefinitions([DEFAULT_DEFINITION])
            } else {
                setDefinitions(savedDefinitions)
            }
            
            toast.success('Successfully updated Instructions and Definitions')
        } else {
            const { definitions: savedDefinitions } = await actions.saveInstructionAndDefinitions(
                instruction, templateName, null, chainerConstants, system, templateId)
            
            setChainerConstants(savedDefinitions)
            
            toast.success('Successfully updated Instructions and Definitions')
        }
        
    }
    
    const handleDefinitionValue = (key, idx, value) => {
        definitions[idx] = {
            ...definitions[idx],
            [key]: value,
        }
        const newArr = [...definitions]
        
        setDefinitions(newArr)
    }
    
    const addNewDefinition = () => {
        setDefinitions([...definitions, DEFAULT_DEFINITION])
    }
    
    const deleteDefinition = async (definitionId, idx) => {
        
        if (definitionId) {
            
            await actions.deleteDefinition(definitionId)
            setDefinitions(definitions.filter(def => def.id !== definitionId))
            
        } else {
            
            definitions.splice(idx, 1)
            setDefinitions([...definitions])
            
        }
        
    }
    
    useEffect(() => {
        if (!templateId)
            return
        
        actions.findInstructionAndDefinitions(templateId).then(data => {
            
            setInstruction(data.template.instructions)
            
            if (system === ST_CHAINER_KEY) {
                
                const chainerDefinitions = CHAINER_DEFINITION.map(cd =>
                    data.definitions.find(dd => dd.key === cd.key) || cd)
                
                setChainerConstants(chainerDefinitions)
                
            } else {
                
                setDefinitions(data.definitions.length ? data.definitions : [DEFAULT_DEFINITION])
                
            }
            
        })
    }, [templateId])
    
    return (
        <div>
            
            <CustomModal
                open={showInstructionDefinitionModal}
                modalHandler={() => setShowInstructionDefinitionModal(false)}
                className="max-w-[95vw]">
                <ModalHeader>
                    <span className='pr-1'> Edit Template: </span>
                    <input
                        type='text'
                        className='input input-bordered'
                        value={templateName}
                        onChange={e => setTemplateName(e.target.value)} />
                </ModalHeader>
                <ModalBody className="flex flex-col w-full gap-2 max-h-[calc(100vh-200px)]">
                    {/* <div className="flex tooltip tooltip-right w-fit"
                        data-tip="Mark as Base Template so all users can see Visible Definitions">
                        <Toggle
                            label="Base Template"
                            className="gap-2 cursor-pointer"
                            checked={baseTemplate}
                            onChange={setBaseTemplate} />
                    </div> */}
                    <div className="flex gap-2">
                        <div className={cn('flex flex-col gap-4 w-full', {
                            'flex-1': system === 'value_extractor' || system === 'chainer',
                        })}>
                            <label htmlFor="instruction">Instructions</label>
                            <textarea
                                id="instruction"
                                type="textarea"
                                className="input input-bordered w-full h-[calc(100vh-280px)]"
                                value={instruction}
                                onChange={e => setInstruction(e.target.value)}></textarea>
                        </div>
                        
                        {system === 'chainer' && (
                            <div className="flex-1">
                                <div className="flex flex-row gap-4 justify-between w-full mb-4">
                                    <label htmlFor="definitions"> Definitions </label>
                                </div>
                                
                                <div className="overflow-auto h-[calc(100vh-280px)]">
                                    {chainerConstants.map((definition, idx) => {
                                        return (
                                            <div key={definition?.id} className="bg-base-100 rounded mb-2 p-4">
                                                <div className="flex justify-around gap-4">
                                                    <div className="flex flex-col w-4/12">
                                                        <label htmlFor="vname"> Tool Name </label>
                                                        <input
                                                            id="vname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition?.key}
                                                            disabled={true} />
                                                    </div>
                                                    <div className="flex flex-col self-center w-8/12">
                                                        <label htmlFor="def"> Tool Instructions </label>
                                                        <textarea
                                                            id="def"
                                                            className="input input-bordered h-20 w-full"
                                                            value={definition?.definition}
                                                            onChange={e => {
                                                                chainerConstants[idx] = {
                                                                    ...definition,
                                                                    definition: e.target.value,
                                                                }
                                                                const newArr = [...chainerConstants]
                                                                
                                                                setChainerConstants(newArr)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        
                        {system === 'value_extractor' && (
                            <div className="flex-1 ">
                                <div className="flex flex-row gap-4 justify-between w-full mb-1">
                                    <label htmlFor="definitions"> Definitions </label>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => addNewDefinition()}> <MdAdd /> Add Definition </button>
                                </div>
                                
                                <div className="overflow-auto h-[calc(100vh-280px)]">
                                    {definitions.map((definition, idx) => (
                                        <div key={definition.id || idx} className="bg-base-100 rounded mb-2 p-4">
                                            <div className="flex justify-around gap-4">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col">
                                                        <label htmlFor="vname"> Value Name </label>
                                                        <input
                                                            id="vname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition.key}
                                                            onChange={e =>
                                                                handleDefinitionValue('key', idx, e.target.value)} />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label htmlFor="dname"> Display Name </label>
                                                        <input
                                                            id="dname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition.displayName}
                                                            onChange={e =>
                                                                handleDefinitionValue('displayName',
                                                                    idx, e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col self-center w-8/12">
                                                    <div className="flex">
                                                        <Toggle label="Visible"
                                                            checked={definition.visible}
                                                            className="gap-2 cursor-pointer mb-2"
                                                            onChange={visible =>
                                                                handleDefinitionValue('visible', idx, visible)} />
                                                    </div>
                                                    <label htmlFor="def"> Definition </label>
                                                    <textarea
                                                        id="def"
                                                        className="input input-bordered h-20 w-full"
                                                        value={definition.definition}
                                                        onChange={e =>
                                                            handleDefinitionValue('definition', idx, e.target.value)} />
                                                </div>
                                                <button
                                                    onClick={() => deleteDefinition(definition.id, idx)}
                                                    className='cursor-pointer text-red-400
                                                        hover:text-red-600 self-start'>
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                
                </ModalBody>
                <ModalFooter>
                    <button
                        className='btn btn-error'
                        onClick={() => setShouldDeleteTemplate(true)}>
                        Delete
                    </button>
                    <button
                        className="btn btn-primary btn-outline"
                        onClick={() => setShowInstructionDefinitionModal(false)}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => createDefinition()}>
                        Update
                    </button>
                </ModalFooter>
            </CustomModal>
            
            {shouldDeleteTemplate && (
                <ConfirmationModal
                    showModal={shouldDeleteTemplate}
                    showHideModal={() => setShouldDeleteTemplate(false)}
                    title="Delete Template"
                    message={`Are you sure you want to delete the template "${templateName}"? Be aware if the template 
                    is deleted, all examples under this template will be deleted as well`}
                    onConfirm={() => deleteTemplate()} />
            )}
        </div>
    )
}

export default InstructionAndDefinitionModal